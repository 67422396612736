import React from 'react'
import { Link } from 'gatsby'

import '../components/globalStyle.css'
import '../components/product.css'

import Header from '../components/header'
import BackButton from '../components/backButton'

const productTAL= () => (
  <div className='productTALGroup'>
    <Header />

    <BackButton />

    <div className="product">

      <div className="productText">

        <h2> TAL : A LABS DESIGN ACCELERATOR </h2>
        <p className="productInfo">
          <i>
            Jan 2019 - Mar 2019<br></br>
            Researched with Katie Johnson
          </i>
        </p>

        <p>
          The Labs Design Team is a small group of product designers and researchers within ConsenSys. They offer focused user research, UX design, and design strategy to startups being incubated in the ConsenSys ecosystem. I briefly joined them as a junior researcher while they tested a new accelerated process with a couple of brand new products. I've focused here on one of those, TAL.

          TAL is a platform and protocol for dis-intermediated crypto backed loans. <b>I helped Katie execute a research plan aimed at bringing an MVP of this product to market within 3 months and contributed to team strategy meetings.</b>
        </p>

        <h4>Design Thinking Workshop</h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/TAL/TALDesignThinking.jpg')} />
        <figcaption>We started out with design thinking sessions between the product team and the design team. We identified mission, assumptions, target user groups, and a roadmap</figcaption>
      </figure>

      <div className="productText">
        <h4>Generative Research</h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/TAL/TALGenerative.svg')} />
        <figcaption>I assisted Katie with interview guides for generative research. We wanted to validate assumptions we had found in the workshop and gain direction.</figcaption>
      </figure>

      <div className="productText">
        <h4>Co-Design Sessions</h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/TAL/TALCodesignSession1.jpg')} />
        <figcaption>We used learning from generative research to frame several co-design sessions with users. The goal was to identify and sketch out their ideal flow and gain a really concrete understanding of their needs.</figcaption>
      </figure>

      <div className="productText">
        <h4>Usability Testing</h4>
      </div>

      <figure className="productImg">
        <img src={require('../img/TAL/TALUsabilityCards.png')} />
        <figcaption>Once the prototype was built, we wrote guides for, and set up a round of usability testing. I then card sorted results to easily surface trends in feedback</figcaption>
      </figure>

      <div className="productText">
        <h4>Moving On</h4>
        <p>TAL will be launching sometime in mid April, and I'm looking forward to seeing the results of the accelerator!</p>


      </div>
    </div>
  </div>
)

export default productTAL
